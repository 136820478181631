<template>
  <div>
    <b-container>
      <b-row>
        <b-col col-xs="12" class="embed-responsive">
          <iframe
            dir="rtl"
            class="bg-white responsive-iframe embed-responsive-item"
            id="myIframe"
            allowfullscreen
          >
          </iframe>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { BContainer, BRow, BCol } from "bootstrap-vue";
export default {
  components: {
    BContainer,
    BRow,
    BCol,
  },
  data() {
    return {
      templateContentToBePreviewd: null,
    };
  },
  methods: {
    insertStyle_Script() {
      const myIframeDocument =
        document.getElementById("myIframe").contentDocument;

      let theScript = document.createElement("script");
      theScript.src = "https://dev.appsmanager.co/dynamicsrpt/btstrp/bootstrap.min.js";
    //   theScript.src = "https://app.appsmanager.co/dynamicsrpt/btstrp/bootstrap.min.js";
      myIframeDocument.head.append(theScript);

      let theStyle = document.createElement("link");
      theStyle.href = "https://dev.appsmanager.co/dynamicsrpt/btstrp/bootstrap.css";
    //   theStyle.href = "https://app.appsmanager.co/dynamicsrpt/btstrp/bootstrap.css";
      theStyle.rel = "stylesheet";
      myIframeDocument.head.append(theStyle);

      let theFAStyle = document.createElement("link");
      theFAStyle.href = "https://dev.appsmanager.co/dynamicsrpt/btstrp/all.min.css";
    //   theFAStyle.href = "https://app.appsmanager.co/dynamicsrpt/btstrp/all.min.css";
      theFAStyle.rel = "stylesheet";
      document.head.append(theFAStyle);
    },
  },
  computed: {},
  created() {},
  mounted() {
    this.templateContentToBePreviewd = JSON.parse(
      localStorage.getItem("templateContent")
    );
    document
      .querySelector("iframe")
      .contentDocument.write(this.templateContentToBePreviewd);
    this.insertStyle_Script();
  },
};
</script>
<style scoped>
* {
  all: initial;
}
.responsive-iframe {
  position: absolute;
  top: 5% !important;
  left: 10% !important;
  right: 10% !important;
  bottom: 0;
  width: 80%;
  height: 80%;
}
</style>
